import Box from '@mui/system/Box';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from '@mui/material';
import { ISong } from '../../interfaces';
import { isUrlWrong } from '../../utils';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

type SongFieldProps = {
  onChange: (newSong: ISong) => void;
  song: ISong;
};

function SongField({ song, onChange }: SongFieldProps) {
  return (
    <Box display="flex" gap={2} my={2}>
      <TextField
        value={song.name}
        onChange={e => {
          onChange({ ...song, name: e.target.value });
        }}
        required
        id={`songName`}
        label="Nome canzone"
      />
      <TextField
        value={song.author}
        onChange={e => {
          onChange({ ...song, author: e.target.value });
        }}
        required
        id={`songAuthor`}
        label="Autore/autrice canzone"
      />
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              value={song.isOriginal}
              onChange={e => {
                //FIXME debug this value
                onChange({ ...song, isOriginal: e.target.checked });
              }}
            />
          }
          label="È inedito?"
        />
      </Box>

      <Box display="flex" alignItems="flex-start">
        <Box display="flex" flexDirection="column" gap={2}>
          {song.urls.map((url, urlIdx) => (
            <Box display="flex" alignItems="center" key={urlIdx}>
              <TextField
                value={url}
                onChange={e => {
                  const newSongUrls = [...song.urls];
                  newSongUrls[urlIdx] = e.target.value;
                  onChange({ ...song, urls: newSongUrls });
                }}
                required
                error={isUrlWrong(url)}
                label="Link canzone"
              />
              <IconButton
                onClick={() => {
                  if (urlIdx > 0) {
                    onChange({ ...song, urls: song.urls.splice(urlIdx, 1) });
                  } else {
                    onChange({ ...song, urls: song.urls.concat('') });
                  }
                }}
              >
                {urlIdx > 0 ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default SongField;
